/*!
 * main.js
 * Author: Corey Keller
 */
!function(){"use strict";function a(){var a={};return a.name=$('form [name="name"]').val(),a.email=$('form [name="email"]').val(),a.phone=$('form [name="phone"]').val(),a.message=$('form [name="message"]').val(),a.gotcha=$('form [name="_gotcha"]').val(),a}function b(a){var b="Spokane Techs Form Submission: "+a.name;""===a.gotcha&&$.ajax({url:"https://formspree.io/help@spokanetechs.com",method:"POST",data:{name:a.name,email:a.email,phone:a.phone,message:a.message,_subject:b},dataType:"json",success:c(),failure:function(a){console.log(a)}})}function c(a){$(".st-form").html("<h3>Your message was successfuly delivered!</h3>")}function d(){var a=!0;return a=!!e($('[data-form-type="group"]'))&&a,a=!!f($('[data-form-type="text"]'))&&a}function e(a){var b=!1;return a.each(function(){var a=this.value;0!==a.length&&/\S/.test(a)&&(b=!0)}),b===!1&&a.closest(".st-form__input-wrapper").addClass("st-form__error"),b}function f(a){var b=!0;return a.each(function(){var a=this.value,c=this.value.length;0!==c&&/\S/.test(a)||($(this).closest(".st-form__input-wrapper").addClass("st-form__error"),b=!1)}),b}$(document).ready(function(){
//expand and collapse all business services at once.
$(".st-services__tab-main").on("click",function(){
//this adds active class to main service dropdown + hides and removes the extra dark bar when the main tab is not expanded
$(this).closest(".st-services").find("#st-services__bottom-bar").toggleClass("st-services__bottom-bar"),$(this).toggleClass("st-services__tab-main--active")}),$(".st-services__tab").on("click",function(){
//add active class to menu item + remove active class from all other items in menu
$(this).closest(".st-services").find(".st-services__tab").removeClass("st-services__item--active"),$(this).addClass("st-services__item--active"),
//add hidden class to menu item + remove hidden class from all other items in menu
$(this).closest(".st-services").find(".st-services__hidden--md").addClass("st-services__hidden"),$(this).children(".st-services__hidden--md").removeClass("st-services__hidden");
//get content from correct service and display it into the single column row on ipad
var a=$(this).children(".st-services__hidden--md").text();$(this).siblings(".st-services__item-content").children("p").text(a)})}),
//on form submit
$(".st-form__submit").on("click",function(){if($(".st-form__input-wrapper").removeClass("st-form__error"),d()&&$(this).closest(".st-form__input-wrapper").hasClass("st-form__loading")!==!0){
// initiate sending spinner
$(this).closest(".st-form__input-wrapper").addClass("st-form__loading");var c={};c=a(),b(c)}})}();